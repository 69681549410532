
import { Vue, Component } from "vue-property-decorator";
import AuthService from "@/services/auth_service";
import { Roles } from "@/models/user/roles";

@Component
export default class Login extends Vue {
  protected error = "";
  protected authService = new AuthService();

  created() {
    this.checkAuth();
  }

  public async submitUpload(e: Event): Promise<void> {
    this.error = "";
    try {
      const csv = (e!.target as any)[0].files[0];
      const formData = new FormData();
      formData.append("csvFile", csv);
      const res = await this.authService.postCsvForEncryption(formData);
      this.downloadFile(res)
    } catch (err) {
      if (!err.message || err.statusCode == 500) {
        this.error = "Something went wrong";
      } else {
        this.error = err.message;
      }
    }
  }

  protected downloadFile(csvData: string) {
    var aLink = document.createElement('a');
    aLink.download = 'Account-requests-with-keys.csv';
    aLink.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csvData)}`;
    var event = new MouseEvent('click');
    aLink.dispatchEvent(event);
}

  protected async checkAuth(): Promise<void> {
    try {
      const { role } = await this.authService.checkAuth();
      if (role !== Roles.Admin) {
        this.$router.push("/");
      }
    } catch (err) {
      this.$router.push("/");
    }
  }
}
